@import "modules/responsive-type.scss";
@import 'https://fonts.googleapis.com/css?family=Baloo+Tamma';
@import 'https://fonts.googleapis.com/css?family=Lato';
$primary: #0e76bc;

/* MAIN COLOR */

$secondary: #64af45;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop
html,
body {
    height: 100%;
}

html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p,
ol,
ul {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

a {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Bowlby One SC', cursive;
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
    font-family: 'Bowlby One SC', cursive;
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
    font-family: 'Bowlby One SC', cursive;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f6f6f6+47,ededed+100;White+3D+%231 */
    background: #ffffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 15px;
            display: flex;
            align-items: center;
            color: $primary;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $primary;
                outline: 0;
            }
            &:hover {
                background: transparent;
                border-radius: 5px;
                color: $secondary;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) {
        margin: 11px 15px 8px 0px;
    }
}


/** LOGIN FORM **/

@mixin btn1 {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        box-shadow: 0px 0px 3px lighten($blk, 10%);
    }
}

@mixin btn2 {
    @include btn1;
    background: darken($wht, 5%);
    color: $primary;
    border: none;
    &:hover {
        background: $wht;
        color: $primary;
        border: none;
    }
}

.modal-dialog {
    max-width: 350px;
    width: 100%;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: none;
        border-bottom: 1px solid darken($primary, 20%);
        text-align: center;
    }
    a {
        @include btn1;
        display: block;
        width: 75%;
    }
    input[type="submit"] {
        @include btn1;
        display: block;
        width: 75%;
        color: white !important;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

input#username {
    margin-bottom: 20px;
}

h1 {
    font-size: 3em;
    font-weight: bold;
    line-height: 1.5em;
    font-family: 'Baloo Tamma', cursive;
    @media (max-width: 767px) {
        font-size: 2em;
    }
}

h2 {
    font-size: 2.5em;
    line-height: 1.5em;
    font-family: 'Baloo Tamma', cursive;
    @media (max-width: 767px) {
        font-size: 1.5em;
    }
}

//  start banner sections
.banner {
    width: 100%;
    // height: 100%;
    background: $primary;
    position: relative;
}

.cta {
    margin: 50px;
    a {
        background: #638c28;
        font-size: 25px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 100;
        padding: 30px 60px;
        margin-top: 35px;
        transition: all 0.3s ease 0s;
        @media(max-width: 538px) {
            font-size: 1em;
        }
        @media(max-width:408px) {
            font-size: 0.75em;
            padding: 10px 10px;
        }
    }
    @media(max-width: 767px) {
        margin: 25px 0 45px 0;
    }
    a:hover {
        background: rgba(255, 255, 255, 0.2);
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        color: #fafafa;
        box-shadow: 0px 0px 9px #fff
    }
}

.frt-banner {
    background: url('../img/frt-couple.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding: 150px 0px;
    @media(max-width: 1680px) {
        background: url('../img/frt-couple.jpg') no-repeat 50%;
        background-attachment: scroll;
        padding: 125px 0px;
    }
    @media(max-width: 1199px) {
        background: url('../img/frt-couple.jpg') no-repeat 50%;
        background-attachment: scroll;
        padding: 41px 0px;
    }
    img {
        margin: 0 auto;
    }
    h1,
    h2,
    p,
    a {
        color: #fafafa;
    }
}

.snd-banner {
    background: url('../img/snd-couple.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding: 150px 0px;
    @media(max-width: 1680px) {
        background: url('../img/snd-couple.jpg') no-repeat;
        background-attachment: scroll;
        background-position: 30% 30%;
        padding: 150px 0px;
    }
    @media(max-width: 768px) {
        background: url('../img/snd-couple.jpg') no-repeat;
        background-attachment: scroll;
        background-position: 30% 20%;
        padding: 160px 0px;
    }
    .ins-banner {
        margin-top: 60px;
        @media (max-width: 768px) {
            margin: 0px;
        }
    }
    .cta {
        a:hover {
            background: rgba(255, 255, 255, 0.9);
            color: #333;
        }
    }
    h1,
    h2,
    p,
    a {
        color: #fafafa;
    }
}

.trd-banner {
    background: url('../img/trd-couple.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding: 150px 0px;
    @media(max-width: 1680px) {
        background: url('../img/trd-couple.jpg') no-repeat;
        background-attachment: scroll;
        background-position: 30% 20%;
        padding: 250px 0px;
    }
    @media(max-width: 768px) {
        background: url('../img/trd-couple.jpg') no-repeat;
        background-attachment: scroll;
        background-position: 30% 20%;
        padding: 250px 0px;
    }
    .ins-banner {
        margin-top: 150px;
        @media(max-width: 768px) {
            margin-top: 0px;
        }
    }
    .cta {
        a:hover {
            background: rgba(255, 255, 255, 0.9);
            color: #333;
        }
    }
    h1,
    h2,
    p,
    a {
        color: #fafafa;
    }
}

// .ins-banner {
//     padding: 140px 0;
//     @media(max-width: 767px) {
//         padding: 50px 0;
//     }
// }
//  edn banner section

/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    // margin-top: 50px;
    background: $primary;
    color: $footerLinks;
    a {
        color: $footerLinks;
        &:hover {
            color: lighten($footerLinks, 10%);
        }
    }
}

.logo {
    max-width: 225px;
    padding: 10px;
}

@media (max-width: 767px) {
    .logo {
        max-width: 150px;
        padding: 8px;
    }
}

input#username,
input#password {
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: block;
    margin: 0px auto;
}

body.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}